<template>
  <div class="container-main-content auth-success">
    <div class="content-inner">
      <Header />
      <div class="content-inner__body">
        <h4 class="content-inner__body-title mb-2">
          Đặt lại mật khẩu thành công
        </h4>
        <p class="f14 mb-4">Bạn đã có thể đăng nhập bằng mật khẩu mới.</p>
        <button type="button" class="btn btn-primary" @click="handleLogin">
          ĐĂNG NHẬP
        </button>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      currentQueries: window.location.href.includes("?")
        ? window.location.href.substring(window.location.href.indexOf("?"))
        : "",
    };
  },
  methods: {
    handleLogin() {
      const callback = this.$route.query.callbackUri;
      if (callback) {
        console.log("has callback");
        window.location.href = callback;
      } else {
        this.$router.push({
          path: `/login${this.currentQueries}`,
        });
      }
    },
  },
};
</script>
